import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>TOOTH BLEACHING</h2>
      <h3>What is it?</h3>
      <p>Like hair and skin, teeth vary in colour. Some are yellower or darker than others, even when they are quite healthy. Teeth tend to get darker as people get older. Teeth sometimes become darker if their roots have been damaged or diseased and the nerve has died. Tooth colour can be very effectively lightened with Hydrogen Peroxide (bleach), used on either the outside or the inside of the tooth.  Tooth bleaching is completely safe as long as the bleach does not touch your gums and burn them.  Dentists use a special jelly bleach.</p>
      <h3>What will my dentist do?</h3>
      <p>Where the nerve of a tooth has died (through damage or disease) and the tooths root has been filled, bleach is put inside the tooth, under a temporary dressing. The bleaching agent remains in the tooth for about a week before being cleaned out so that a white filling can be placed.</p>
      <p>There are two ways of bleaching the outside of teeth. One takes place at home and the other in the dentists  surgery.</p>
      <p>Home bleaching works like this: (we only offer this method at the practice)</p>
      <p>The dentist will give you weak bleach jelly to use at home in a tray which fits closely round your teeth. The tray makes sure that the jelly does not burn your gums. You will need to put the tray in your mouth for a few hours on several occasions, as instructed by your dentist (probably over a period of weeks). The dentist will need to use a putty-like material to make moulds of your mouth (called impressions), so a dental technician can make the tray to fit. At your next appointment, your dentist will check that the tray fits and show you how to put the bleach in the tray at home.</p>
      <p>Bleaching in the surgery works like this:</p>
      <p>Your dentist will use a stronger bleach jelly than is used for home bleaching. Dentists use this method to bleach all the teeth at the same time in either your upper or lower jaw. The strong bleach will touch the teeth for less time than with home bleaching. The dentist might protect your gums. They will paint the bleach onto the teeth and either shine a special light at them or just leave the bleach on the teeth for some time. The bleach is then washed off with water.</p>
      <p>The dentist will make a note of your tooth colour using model teeth in different shades before they treat you. Look at the colour match yourself before the treatment, so you can compare with the colour after bleaching. Your teeth may be sensitive to hot and cold food and drink for a few days after bleaching.</p>
      <h3>What are the benefits?</h3>
      <p>Bleaching improves the appearance of your teeth without removing any of the natural tooth surface. Bleaching is a better option than a crown  or veneer if you want to lighten the colour of healthy teeth,  as the placing of crowns and veneers involves changing the shape  of the tooth.</p>
    </LightboxLayout>
  )
}

export default Page